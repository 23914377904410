import { ErpTableOptions } from '@/types/type';
import { computed, defineComponent, ref } from 'vue';
export default (Instance: any) => {
  const tableRef = ref();
  const status = [
    {
      value: false,
      label: '否',
    },
    {
      value: true,
      label: '是',
    },
  ];

  const columnList: ErpTableOptions[] = [
    {
      label: '销售合同号',
      prop: 'businessContractNo', // 属性
      minWidth: 143,
      type: 'input',
      value: '',
    },
    {
      label: '是否代采',
      prop: 'isNeedCg',
      propDesc: 'isNeedCgDesc',
      minWidth: 130,
      type: 'signSelect',
      options: status,
      className: '',
      value: '',
    },
    {
      label: '供应商合同号',
      prop: 'supplierContractNo',
      minWidth: 160,
      type: 'input',
      value: '',
      className: '',
    },
    {
      label: '供应商',
      prop: 'supplierName',
      minWidth: 160,
      type: 'input',
      value: '',
      className: '',
    },
    {
      label: '客户',
      prop: 'customerName',
      minWidth: 183,
      type: 'input',
      value: '',
      className: '',
    },
    {
      label: '国家',
      prop: 'country',
      propDesc: 'countryDesc',
      minWidth: 103,
      type: 'input',
      value: '',
      className: '',
    },
    {
      label: '厂号',
      prop: 'plantNo',
      minWidth: 122,
      type: 'input',
      value: '',
      className: '',
    },
    {
      label: '抬头类型',
      prop: 'consigneeType',

      propDesc: 'consigneeTypeDesc', // 需要转换的列值的属性名
      minWidth: 128,

      type: 'signSelect',
      value: '',
      options: 'consignee_type',
    },
    {
      label: '抬头',
      prop: 'consigneeShortName',
      minWidth: 105,
      type: 'input',
      value: '',
      className: '',
    },

    {
      label: '启运日',
      prop: 'shippingStartDate',
      type: 'time',
      className: '',
      minWidth: 122,
      filterProp: ['shippingStartDateStartDate', 'shippingStartDateEndDate'],
      value: [],
    },
    {
      label: '到港日',
      prop: 'shippingEndDate',
      type: 'time', // 筛选类型 time 日期
      value: [],
      minWidth: 122,
      filterProp: ['shippingEndDateStartDate', 'shippingEndDateEndDate'],
    },
    {
      label: '正本状态',
      prop: 'originStatus',
      propDesc: 'originStatusDesc',
      minWidth: 130,
      type: 'multiSelect', // 筛选类型 input 输入项
      value: [],
      options: 'origin_progress_type_status',
    },
    {
      label: '正本文件',
      prop: 'originFileStatus',
      propDesc: 'originFileStatusDesc',
      minWidth: 130,
      type: 'multiSelect',
      options: 'order_payment_notice_origin_file_status',
      value: [],
    },
    {
      label: '预计申请付尾款日期',
      prop: 'expectApplyPayDate',
      minWidth: 200,
      type: 'time', // 筛选类型 time 日期
      filterProp: ['expectApplyPayDateStartDate', 'expectApplyPayDateEndDate'],
      value: [],
    },
    {
      label: '预计付尾款金额',
      prop: 'expectPayAmount',
      minWidth: 172,
      type: 'number',
      filterProp: ['expectPayAmountMin', 'expectPayAmountMax'],
      value: [],
    },
    {
      label: '收到保证金日期',
      prop: 'depositDate',
      minWidth: 172,
      type: 'time',
      className: '',
      filterProp: ['depositDateStartDate', 'depositDateEndDate'],
      value: [],
    },

    {
      label: '正本文件',
      prop: 'originFileStatus',
      propDesc: 'originFileStatusDesc',
      minWidth: 130,
      type: 'multiSelect',

      options: 'order_payment_notice_origin_file_status',
      value: [],
    },
    {
      label: '预计申请付尾款日期',
      prop: 'expectApplyPayDate',
      minWidth: 200,
      type: 'time', // 筛选类型 time 日期
      filterProp: ['expectApplyPayDateStartDate', 'expectApplyPayDateEndDate'],
      value: [],
    },
    {
      label: '预计付尾款金额',
      prop: 'expectPayAmount',
      minWidth: 172,
      type: 'number',
      filterProp: ['expectPayAmountMin', 'expectPayAmountMax'],
      value: [],
    },
    {
      label: '申请状态',
      prop: 'applyStatus',
      propDesc: 'applyStatusDesc',
      type: 'multiSelect',
      options: 'order_payment_notice_apply_status',
      value: [],
      className: '',
      minWidth: 130,
    },
    {
      label: '申请时间',
      prop: 'applyTime',
      minWidth: 174,
      type: 'time',
      className: '',
      filterProp: ['applyTimeStartDate', 'applyTimeEndDate'],
      value: [],
    },
    {
      label: '审核状态',
      prop: 'auditStatus',
      propDesc: 'auditStatusDesc',
      minWidth: 130,
      type: 'multiSelect',
      options: 'order_payment_notice_audit_status',
      className: '',
      value: [],
    },
    {
      label: '客户经理',
      prop: 'clientManager',
      minWidth: 128,
      type: 'input',
      value: '',
      className: '',
    },
    {
      label: '采购',
      prop: 'purchase',
      minWidth: 101,
      type: 'input',
      value: '',
      className: '',
    },
    {
      label: '单证对接人',
      prop: 'documentAccess',
      minWidth: 146,
      type: 'input',
      value: '',
      className: '',
    },
    {
      label: '操作',
      prop: '',
      fixed: 'right',
      width: 108,
      isFilter: false,
      type: 'input',
      value: '',
      className: 'erp-btn-groups',
      component: defineComponent({
        template: `
        <el-button   v-if="isShowSubmitBtn && hasPermission('erp:fm:submitFinalPaymentNotice')" type="primary" plain @click="operateFn('提交申请',row)">提交申请</el-button>
        <el-button  v-if="isShowReSubmitBtn && hasPermission('erp:fm:submitFinalPaymentNotice')" type="primary" plain @click="operateFn('重新提交',row)">重新提交</el-button>
        <el-button  v-if="isShowCancelBtn && hasPermission('erp:fm:submitFinalPaymentNotice')"  plain @click="operateFn('取消',row)">取消</el-button>
        `,
        props: ['row', 'col', 'column'],
        emits: ['noop'],
        setup(props) {
          const operateFn = (status, row) => {
            Instance.setupState.operateFn(status, row);
          };
          // 1.审核状态为“审核不通过”或“已取消” auditStatusDesc
          // auditStatus {"1":"待审核","2":"审核不通过","3":"审核通过","4":"已取消"}
          // applyStatus {"0":"未申请","1":"已申请","2":"已取消"}
          // 2.申请状态为“已取消” applyStatusDesc
          const isShowReSubmitBtn = computed(() => {
            const { applyStatus, auditStatus } = props.row;
            return auditStatus === 2 || auditStatus === 4 || applyStatus === 2;
          });
          const isShowSubmitBtn = computed(() => {
            const { applyStatus, auditStatus, canCommit } = props.row;
            return (
              !(auditStatus === 2 || auditStatus === 4 || applyStatus === 2) &&
              canCommit
            );
          });

          const isShowCancelBtn = computed(() => {
            const { applyStatus, auditStatus } = props.row;
            return applyStatus === 1 && auditStatus === 1;
          });
          return {
            isShowSubmitBtn,
            isShowReSubmitBtn,
            isShowCancelBtn,
            operateFn,
          };
        },
      }),
    },
  ];
  return { columnList, tableRef };
};
